import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import FullWidthImage2 from "../components/FullWidthImage2";
import JobRoll from "../components/JobRoll";
import DotMatrix from "../components/DotMatrix";
import HeaderBanner1 from "../components/HeaderBanner1";
import Content5 from "../components/Content5";
import About4 from "../components/About4";
import ThingsWeDo from "../components/ThingsWeDo";
// eslint-disable-next-line
export const IndexPageTemplate = ({ image, title }) => {
  const heroImage = getImage(image) || image;

  return (
    <>
      <FullWidthImage img={heroImage} title={title} />
      <HeaderBanner1 />
      <section className=" mx-auto my-16">
        <div className="w-9/12 mx-auto">
          <About4 />
        </div>
        <div className="w-9/12 mx-auto pt-2 pb-4">
          <DotMatrix />
        </div>
        <div className="py-8 bg-bristol-blue-2 text-white">
          <div className="w-9/12 text-4xl text-center mx-auto">
            Benefits Of Choosing Bristol Place
          </div>
          <p className="w-9/12 mx-auto m-4 text-xl">
          We will work in conjunction with each individual to reach their goals and enhance the quality of their lives. We strive to be proactive and involved in helping people access services that are most beneficial to them.
          </p>
        </div>
        <div className="pt-3">
          <DotMatrix />
        </div>
        <div className="w-9/12 mx-auto text-4xl text-center">What We Do</div>
        <p className="w-9/12 mx-auto mt-3 mb-10 text-xl">
        Bristol Place Corporation's approach to working in the human services field is individualized, engaged and empathetic. We meet individuals where they are at during this time in their lives and provide the support necessary to help them reach their individual goals.
        </p>
        <ThingsWeDo />
        <div className="pt-3 pb-10">
          <DotMatrix />
        </div>
        <div className="w-9/12 mx-auto bg-bristol-blue-2 bg-opacity-50 p-8 rounded shadow-3xl hover:animate-fade-in-down">
          <Content5 />
        </div>
        <div className="mx-auto mt-20 md:pb-6 rounded shadow-3xl">
          <JobRoll />
        </div>
      </section>
      <HeaderBanner1 />
      <FullWidthImage2 />
    </>
  );
};

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,

};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout className="font-sans">
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        mainpitch={frontmatter.mainpitch}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        mainpitch {
          title
          description
        }
      }
    }
  }
`;
