import React from "react";

const FullWidthImage2 = () => {
  return (
    <img
      alt=''
      src={"/img/7-new.jpg"}
      // imgPosition={""}
      className=''
    />
  );
};

export default FullWidthImage2;
