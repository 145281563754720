import React from "react";
import PropTypes from "prop-types";
import { Link, graphql, StaticQuery } from "gatsby";

class JobRollTemplate extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className='w-full mx-auto mt-10 mb-6'>
        <div className="w-full mb-4 md:mb-12 pt-8 mx-auto bg-bristol-blue-2 shadow-3xl text-white">
          <div className="w-9/12 mx-auto flex flex-col">
            <p className='mx-auto py-px mb-4 text-xs font-semibold tracking-wider uppercase'>
              #BristolPlace
            </p>
            <p className='mb-6 text-3xl text-center font-bold leading-none tracking-tight sm:text-4xl mx-auto'>
              Current Employment Opportunities
            </p>
            <p className='md:text-lg mx-auto px-4 pb-6 text-center'>
              There is no better time than now to join our team!
            </p>
          </div>
        </div>
        <div className='grid w-9/12 gap-4 mx-auto mt-2 mb-12 pb-4 rounded-lg'>
          {posts &&
            posts.map(({ node: post }) => (
              <div
                key={post.id}
                className='flex flex-col border-4 p-6 sm:mx-auto sm:flex-row rounded-lg drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)] hover:drop-shadow-xl'
              >
                <div className='' key={post.id}>
                  <article
                    className={` ${post.frontmatter.featuredpost ? "is-featured" : ""
                      }`}
                  >
                    <div className='mb-4 sm:mb-2 md:mb-0'>
                      <div className=''>
                        <div className='flex items-center justify-center w-12 h-12 rounded-full bg-bristol-blue-2 bg-opacity-50 mx-auto'>
                          <svg
                            className='w-10 h-10 text-purple'
                            stroke='currentColor'
                            viewBox='0 0 52 52'
                          >
                            <polygon
                              strokeWidth='3'
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              fill='none'
                              points='29 13 14 29 25 29 23 39 38 23 27 23'
                            />
                          </svg>
                        </div>
                      </div>
                      <div className='mb-3 text-xl font-bold h-20 mx-auto text-center mt-6'>
                        {/* <Link className='mx-auto' to={post.fields.slug}> */}

                        {post.frontmatter.title}
                        {/* </Link> */}
                      </div>
                      {/* <p className='mb-3 text-sm'>{post.frontmatter.date}</p> */}
                      <div className='text-sm'>
                        Full-Time Case Manager needed to assess, create/review
                        care plans and coordinate services with individuals who
                        qualify for CADI, DD and/or BI waivers. Candidate will
                        make referrals for individuals served to community
                        resources based on their assessed need and ensure waiver
                        coordination/documents meet necessary deadlines and meet
                        lead agency guidelines.
                      </div>
                    </div>
                    <br />
                    <div className='flex flex-col'>
                      <p className='mx-auto mb-10 text-sm'>
                        {post.excerpt}
                      </p>
                      <button className='rounded-full p-3 mx-auto bg-bristol-blue-2 bg-opacity-50'>
                        <Link className='font-bold' to={post.fields.slug}>
                          Learn More →
                        </Link>
                      </button>
                    </div>
                  </article>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

JobRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default function JobRoll() {
  return (
    <StaticQuery
      query={graphql`
        query JobRollQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "job-post" } } }
          ) {
            edges {
              node {
                # description
                excerpt(pruneLength: 500)
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  templateKey
                  
                  featuredpost
             
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <JobRollTemplate data={data} count={count} />}
    />
  );
}
