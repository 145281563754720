import React from 'react'

const Content5 = () => {
  return (
      <div className=" md:px-4 py-4 mx-auto lg:px-8 hover:animate-fade-in-down">
        <div className="md:grid grid-cols-1 gap-8 lg:gap-16 lg:grid-cols-2">
          <div
            className="relative h-64 overflow-hidden rounded-lg sm:h-80 lg:h-full lg:order-last"
          >
            <img
              className="absolute inset-0 object-cover w-full h-full animate-fade-in-down"
              src={"/img/6-new.jpg"}
              alt="Man using a computer"
            />
          </div>
          <div className="lg:py-8">
            <h2 className="mt-4 text-center text-4xl md:text-5xl ">Bristol Place Corporation provides...</h2>
            <p className="mt-8 text-xl font-sans">
              Bristol Place provides quality case management services in Hennepin
              County. We work with persons who qualify to receive services through
              CADI, BI, and DD waivers. We also provide non-waivered Rule 185 case
              management. Our referrals come directly from Hennepin County, however,
              if someone is currently receiving services through one of the above
              listed waivers, they can choose to change agencies and work with us.
              Based on our availability, a transfer to our agency may occur. We can
              help walk you through this process.
            </p>
          </div>
        </div>
      </div>
  );
}

export default Content5