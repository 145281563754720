import React from 'react'

const ThingsWeDo = () => {
  return (
    <div>
      <img
        src='/img/things-we-do.png'
        alt='step1'
        // className='w-full h-full'
        className='mx-auto'
      />
    </div>
  );
}

export default ThingsWeDo