import React from 'react'

function About4() {
  return (
    <div className='flex items-center mt-4'>
      <h3 className='text-xl'>
        Bristol Place has provided quality services to persons with
        disabilities since 1981. As a small family-owned business, we
        are able to focus on developing strong professional
        relationships where staff can concentrate on being proactive in
        assisting individuals to reach their goals while remaining safe
        and healthy in the community.
      </h3>
    </div>
  );
}

export default About4
